import React, { useEffect, useRef } from "react"
import gsap from "gsap"
import { Link, navigate } from "gatsby"
import "../styles/aboutStyles.scss";
import Overlay from '../components/Overlays/Overlay'
import {ArrowTop} from '../assets/social-icons'
import {
  useGlobalDispatchContext,
} from '../context/globalContext'
import {RightArrow} from '../assets/text'
import { ScrollTrigger } from "gsap/ScrollTrigger";







const About = () => { 

  gsap.registerPlugin(ScrollTrigger)
  const about1 = useRef(null)
  const about2 = useRef(null)
  const li1 = useRef(null)
  const li2 = useRef(null)
  const li3 = useRef(null)
  const li4 = useRef(null)
  const li5 = useRef(null)
  const li6 = useRef(null)
  const li7 = useRef(null)
  const li8 = useRef(null)
  const li9 = useRef(null)
  const li10 = useRef(null)
  const li11 = useRef(null)
  const li12 = useRef(null)
  const li13 = useRef(null)
  const li14 = useRef(null)
  const li15 = useRef(null)
  const li16 = useRef(null)
  const li17 = useRef(null)
  const li18 = useRef(null)
  const li19 = useRef(null)
  const li20 = useRef(null)

  const dispatch = useGlobalDispatchContext()


  const onCursor = cursorType => {
      dispatch({ type: "CURSOR_TYPE", cursorType: cursorType })
  }

  useEffect(() => {
    onCursor()
    //timeline
    const tl = gsap.timeline()
    tl.to(".front-1", {
      height: 0,
      ease: "Power2.easeOut",
      stagger: 0.2,
      delay: .3,
      duration:  1.3,
    })
      .to(".back-1", {
        duration: 0.8,
        height: 0,
        ease: "Power3.easeOut",
        stagger: 0.2,
        delay: -0.7,
      })
      .to(".intro-overlay", { css: { display: "none" }})
      .from([about1.current, about2.current],{
        duration:1.2,
        delay:-.5,
        y:220,
        skewY:10,
        stagger:.3,
        ease:'Power3.easeOut'
      })
      .from('.banner-section-description-wrapper',{
        duration:.6,
        y:20,
        opacity:0,
      })
    })

    useEffect(()=>{
      gsap.from([li1.current, li2.current, li3.current, li4.current], {
        duration:.8,
        y:70,
        skewY:10,
        stagger:.2,
        ease:'Power3.easeOut',
         scrollTrigger:{
          trigger:'.marquee__inner',
          start: 'top center',
         /*  markers:true */
        } 
      })
      gsap.from([li5.current, li6.current, li7.current],{
        duration:.7,
        y:70,
        skewY:10,
        stagger:.2,
        delay:0.4,
        ease:'Power3.easeOut',
         scrollTrigger:{
          trigger:'.marquee__inner',
          start: 'top center',
         /*  markers:true */
        } 
      })
    },[])

    useEffect(()=>{
      gsap.from([li8.current, li9.current, li10.current, li11.current, li12.current, li13.current, li14.current, li15.current,li19.current, li20.current,li16.current,li17.current,li18.current],{
        duration:.7,
        stagger:.1,
        y:100,
        skewY:10,
        scrollTrigger:{
          trigger:'.marquee__inner',
          start:'center top',
         /*  markers:true */
        }
      })
    }, [])

    const handleMouseEnter = () => {
      gsap.to('.about-footer-arrows svg', {
        duration:.3,
        x:40,
        stagger:.1,
        fill:"#0e0e0e"
    })
    }

    const handleMouseLeave = () => {
      gsap.to('.about-footer-arrows svg', {
        duration:.3,
        x:0,
        stagger:.1,
        fill:'none'
    })
    }

    const handleClick = (e) => {
      e.preventDefault()
      const tl = gsap.timeline()
      tl.to(['.about-footer-wrapper','.about-container'] ,{
        duration:1,
        y:-50,
        opacity:0,
        stagger:.2
      }) 
      setTimeout(()=>{
        navigate('/contact')
      }, 1500)
    }

   const handleHomeClick = (e) => {
     e.preventDefault()
     gsap.to('.about-container',{
       duration:1.2,
       y:-70,
       opacity:0,
       ease:'Power3.easeOut'
     })
     setTimeout(() => {
       navigate('/')
     }, 1200);
   }

  return (
    <>
      <Overlay />
      <div className="about-container">
              <div className="work-banner">
                  <div className="redirect">
                          <ArrowTop/>
                          <Link to='/' onMouseEnter={()=>onCursor('pointer')} onMouseLeave={()=> onCursor('')} onClick={handleHomeClick}>Back to home</Link>
                  </div>
                  <p><span>01</span> - About</p>
              </div>
              <div className="about-banner-section">
                <div className='banner-section-wrapper'>
                    <div className='line-overlay'>
                      <h1 ref={about1}>Want to learn more</h1>
                    </div>
                    <div className='line-overlay'>
                      <h1 ref={about2}>about me <span>?</span></h1>
                    </div>  
                </div>
              </div>
              <div className="banner-section-description">
                  <div className="empty"></div>
                  <div className="banner-section-description-wrapper">
                      <h3>Hi there!</h3>
                      <p>My name is Nikos Tzamalis. <br/> 
                         I'm a 28 year old self taught Front End Developer based in Athens, Greece.<br/>
                         I describe myself as a passionate developer who loves coding and designing new things.<br/>
                         I mostly work now with ReactJS, GatsbyJS and NextJS.<br/>
                         My development stack is focused on performance & accessibility with delightful interactions. I create blazing fast web experience using Jamstack with the help of NextJS and headless CMSs.<br/>
                         In my free time you can find me at the beach &#127754; or on the basketball court ! &#127936;<br/>
                         Feel free to say hello!
                      </p>   
                  </div>
              </div>
      </div>
      <div class="marquee">
        <div class="marquee__inner" aria-hidden="true">
          <span>expirience</span>
          <span>expirience</span>
          <span>expirience</span>
          <span>expirience</span>
        </div>
    </div>
    <div className="about-container">
        <div className="skills-wrapper">
          <div className="skills-wrapper-title">
            <div className="line-overlay">
            <h1 ref={li1}>Services</h1>
            </div>
            
          </div>
          <div className="skills-wrapper-list">
            <ul className='list first'>
              <div className="line-overlay">
                <li ref={li2}>Web Development</li>
              </div>
              <div className="line-overlay">
                <li ref={li3}>Web Design</li>
              </div>
              <div className="line-overlay">
                <li ref={li4}>eCommerce Design</li>
              </div>   
            </ul>
          </div>
          <div className="skills-wrapper-list">
            <ul className='list second'>
              <div className="line-overlay">
                <li ref={li5}>Content Creation</li>
              </div>
              <div className="line-overlay">
                <li ref={li6}>Prototyping</li>
              </div>
              <div className="line-overlay">
                <li ref={li7}>SEO</li>
              </div>
            </ul>
          </div>
        </div>
    </div>
    <div className="about-container">
      <div className="skills-wrapper  second">
      <div className="skills-wrapper-list">
            <ul className='list first'>
              <div className="line-overlay">
              <li ref={li12}>ReactJS / Gatsby</li>
                </div>
              <div className="line-overlay">
                <li ref={li13}>Javascript</li>
              </div>
              <div className="line-overlay">
                <li ref={li14}>CSS / Sass / Styled Components</li>  
              </div>
              <div className="line-overlay">
                <li ref={li15}>Redux / Context API</li>
              </div>
              <div className="line-overlay">
                <li ref={li19}>Contentful</li>
              </div>
              <div className="line-overlay">
                <li ref={li20}>Strapi</li>
              </div>
           </ul>
      </div>
      <div className="skills-wrapper-list">
            <ul className='list first'>
              <div className="line-overlay">
                <li ref={li9}>Adobe XD</li>
              </div>
              <div className="line-overlay">
                <li ref={li10}>Adobe Photoshop</li>
              </div>
              <div className="line-overlay">
                <li ref={li11}>Figma</li> 
              </div> 
              <div className="line-overlay">
                <li ref={li16}>Gsap</li> 
              </div> 
              <div className="line-overlay">
                <li ref={li17}>NextJS</li> 
              </div>
              <div className="line-overlay">
                <li ref={li18}>Wordpress</li> 
              </div> 
           </ul>
        </div>
        <div className="skills-wrapper-title">
            <div className="line-overlay">
              <h1 ref={li8}>What I use</h1>
            </div>  
        </div>
    </div>
    </div>
    <div className="about-footer">
        <div className="about-footer-wrapper">
            <Link to="/contact" onMouseEnter={()=>{
              onCursor('pointer')
              handleMouseEnter()
            }}
            onMouseLeave={()=> {
              onCursor('')
              handleMouseLeave()
            }}
            onClick={handleClick}
            >
              <h1>Let's talk<span>.</span></h1>
            </Link>
            <div className="about-footer-arrows">
                <RightArrow/>
                <RightArrow/>
                <RightArrow/>
            </div>
        </div>
    </div>
    </>
 
  )
}

export default About
